import React, { useState } from 'react'
import ClassNames from 'classnames'
import componentStyles from './AccordianRow.module.scss'

const Nav = ({ id, heading, ...props }) => {
  const [accordianOpen, toggleAccoridan] = useState(false)

  return (
    <div
      id={`accordion${id}`}
      aria-controls={id}
      className={
        accordianOpen
          ? ClassNames(componentStyles.accordianSection, componentStyles.active)
          : componentStyles.accordianSection
      }
    >
      <h3>
        <button
          aria-expanded={accordianOpen}
          onClick={() => toggleAccoridan(!accordianOpen)}
        >
          <span className={componentStyles.accordianHdr}>{heading}</span>
        </button>
      </h3>
      <div
        id={id}
        role='region'
        aria-labelledby={id}
        hidden={!accordianOpen}
        className={componentStyles.accordianPanel}
      >
        {props.children}
      </div>
    </div>
  )
}

export default Nav
